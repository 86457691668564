<template>
    <section id="book">
        <div class="sec-head">
            <h2 v-if="birthday" id="section-heading">
                <p>{{ bookData.secheadPart1 }}<span class="red-text">{{ bookData.secheadPart2 }}</span></p>
            </h2>
            <h2 v-else id="section-heading">
                <p>{{ bookData.secheadPart3 }}<span class="red-text">{{ bookData.secheadPart4 }}</span></p>
            </h2>
            <h3>{{ bookData.secheadPart5 }}</h3>
        </div>
        <div v-if="isDataLoading" class="loaderB-container">
            <div class="loaderB"><p><img loading="lazy" src="@/assets/img/gif/ppc.gif" alt="Loading..."></p><p>  {{ bookData.loading }}...</p></div>
        </div>
        <div v-if="isLoadingProblem" class="loaderB-container">
            <div class="disclaimer">
                <h4 class="red-text disclaimer"> {{ bookData.loadingerror }} <a href="tel:+74952012536">+7(495)201-2536</a></h4>
                <img loading="lazy" src="@/assets/img/notfound220.webp" alt="">
                <p style="margin-top: 1rem;"><button type="button" class="header-btn" @click="goUpdate">{{bookData.update}}</button></p>
            </div>
        </div>
        <div v-else>
            <div v-if="quest === 'all'" class="allQuests" id="bookSystem">
                <div v-if="selectedSlot === null">
                    <div class="links grid-dates desk">
                        <button v-for="(date, index) in dates" :key="index" :class="{ isActive: activeDate === index }" @click="selectDate(index)">
                            <span class="date">
                                {{ new Date(date).getDate() }}
                            </span>
                            <span class="month">
                                {{ new Date(date).toLocaleDateString(bookData.locale, { month: 'short' }) }}
                            </span>
                            <span class="weekday">
                                {{ new Date(date).toLocaleDateString(bookData.locale, { weekday: 'short' }) }}
                            </span>
                        </button>
                    </div>
                    <div class="grid-quest">
                        <template v-for="room in rooms">
                            <div class="slot" v-if="selectedDate && data[room][selectedDate]" :key="room">
                                <div class="quest-name">{{ bookData.roomNames[room] }}</div>
                                <div class="btn-quest">
                                    <button v-for="slot in data[room][selectedDate]" :key="slot" :class="{ 'not-free': slot.status === 'occupied' }" @click="slot.status !== 'occupied' && showBookingForm(room, selectedDate, slot)" class="qSlot">
                                        <div class="time">{{ slot.start_time.slice(0, 5) }}</div>
                                        <div class="price">{{ slot.price }}&#8381;</div>
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- ONEQUEST -->
            <div v-else class="oneQuest" id="bookSystem">
                <div v-if="selectedSlot === null" id="bookSystemOne">
                    <div class="roomName upText">
                        <h4> {{bookData.roomName}} <p class="red-text">{{ bookData.roomNames[quest] }}</p></h4>
                    </div>
                    <div class="grid-quest">
                        <div v-for="(key, i, index) in data[quest]" :key="key" class="slot" :class="{ 'hideIndex': !showAllSlots && index > 6 }">
                            <div class="quest-name date-name upText">{{ formatDate(i, 'long') }}</div>
                            <div class="btn-quest">
                                <button v-for="slot in data[quest][i]" :key="slot" :class="{ 'not-free': slot.status === 'occupied' }" @click="slot.status !== 'occupied' && showBookingForm(quest, i, slot)" class="qSlot">
                                    <div class="time">{{ slot.start_time.slice(0, 5) }}</div>
                                    <div class="price">{{ slot.price }}&#8381;</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="moreDates" class="show-more-btn" @click="toggleMoreSlots">
                        <span class="header-btn" v-if="showAllSlots">{{bookData.hide}}</span>
                        <span class="header-btn" v-else>{{bookData.show}}</span>
                    </div>
                </div>
            </div>
        </div>
            <div v-if="cancelSlot" class="bookingForm">
                    <div v-if="showCancellationMessage" class="success-message">
                        <h4 class="bookHeader"><p>{{bookData.cancelp1}}</p><p class="red-text">{{ bookData.cancelp2 }}</p></h4>
                        <div class="btnBox">
                            <button type="button" class="header-btn" @click="goBack">{{bookData.goback}}</button>
                        </div>
                    </div>
                    <div v-else-if="showCancellationError" class="success-message">
                        <h4 class="bookHeader"><p>{{ bookData.cancelep1 }} </p><p class="red-text"> {{ bookData.cancelep2 }}</p></h4>
                        <div class="btnBox">
                            <button type="button" class="header-btn" @click="goBack">{{bookData.goback}}</button>
                        </div>
                    </div>
                    <div v-else  class="bookHeader">
                        <p>{{ bookData.sent }}</p>
                        <img loading="lazy" src="@/assets/img/gif/ppc.gif" alt="Loading..." >
                    </div>
            </div>
            <div v-if="selectedSlot" class="bookingForm">
                <div v-if="isFormLoading">
                    <div v-if="showErrorMessage" class="error-message ">
                        <h4 class="bookHeader">{{ bookData.wannabook }}</h4>
                        <div class="btnBox">
                            <span class="formText">{{bookData.roomName}}<h4 class="red-text formText">{{ bookData.roomNames[selectedRoom] }}</h4></span>
                        </div>
                        <div class="btnBox">
                            <span class="formText">{{ bookData.date }}: <p class="formData">{{ formatDate(selectedDate, 'short') }}</p></span>
                            <span class="formText">{{bookData.time}}: <p class="formData">{{ selectedSlot.start_time.slice(0, 5) }}</p></span>
                        </div>
                        <div class="l-element">
                            <h4 class="bookHeader"> {{ bookData.errorMessage[0] }} </h4>
                            <h4 class="red-text bookHeader"> {{ bookData.errorMessage[1] }} </h4>
                        </div>
                        <div class="btnBox"><button type="button" class="header-btn " @click="goBack">{{bookData.choose}}</button></div>
                    </div>
                    <div v-else-if="showSuссessMessage" class="error-message ">
                        <h4 class="bookHeader"><p><span class="red-text">{{bookData.success[0]}}</span>, <span>{{bookData.success[1]}}:</span></p></h4>
                        <div class="btnBox">
                            <span class="formText">{{bookData.roomName}}:<h4 class="red-text formText">{{ bookData.roomNames[selectedRoom] }}</h4></span>
                            <span class="formText">{{bookData.date}}: <p class="formData">{{ formatDate(selectedDate, 'short') }}</p></span>
                        </div>
                        <div class="btnBox">
                            <span class="formText">{{bookData.time}}: <p class="formData">{{ selectedSlot.start_time.slice(0, 5) }}</p></span>
                            <span class="formText">{{bookData.price}}: <p class="formData">{{ calculatedPrice }}&#8381;</p></span>
                        </div>
                        <div v-if="changeData" class="l-element check-element">
                            <h5 class="bookHeader">
                                <form class="c-form">
                                    <p class="iptBox">
                                        <input type="name" class="b-element input" :placeholder="formData.name" id="name" v-model="formData.name" @input="checkFormValidity" required>
                                        <input
                                        v-model="formData.phone"
                                        type="tel"
                                        id="phoneF"
                                        class="b-element Input"
                                        :placeholder="formData.phone"
                                        @input="handlePhoneInput"
                                        required
                                    />
                                    </p>
                                    <p class="iptBox">
                                        <input 
                                        v-model="formData.email" 
                                        type="email" 
                                        id="emailF"
                                        class="b-element Input" 
                                        :placeholder="formData.email" 
                                        required
                                        />
                                    </p>
                                    <div v-if="showPhoneError">
                                        <span  class="red-text errorMSG">{{ bookData.contact[0] }}</span>
                                    </div>
                                    <div v-if="showEmailError">
                                        <span  class="red-text errorMSG">{{ bookData.contact[1] }}</span>
                                    </div>
                                    <p class="btnBox btnForm">
                                        <button
                                            :disabled="!isFormValid"
                                            class="header-btn"
                                            :style="{ opacity: isFormValid ? 1 : 0.5 }"
                                            :class="{ 'no-hover': !isFormValid }"
                                            @mouseover="handleMouseOver"
                                            @mouseout="handleMouseOut"
                                            @click="submitBookingDataChange"
                                        >
                                            {{ bookData.pcb[4] }}
                                        </button>
                                    </p>
                                </form>
                            </h5>
                        </div>
                        <div v-else class="l-element" >
                            <h5 class="bookHeader"> {{ bookData.emailsent[0] }} </h5>
                            <h5 class="red-text bookHeader"> {{ bookData.emailsent[1] }} </h5>
                            <h5 class="bookHeader">
                                <p><span class="red-text">{{ bookData.dataok[0] }}</span> <span>{{ formData.name }}</span></p>
                                <p><span class="red-text">{{ bookData.dataok[1] }}</span> <span>{{ formData.phone }}</span></p>
                                <p><span class="red-text">{{ bookData.dataok[2] }}</span> <span>{{ formData.email }}</span></p>
                                <button type="button" class="header-btn b-element" @click="goChange">{{bookData.pcb[3]}}</button>
                            </h5>
                            <h4 class="red-text bookHeader"> {{ bookData.emailsent[2] }}</h4>
                        </div>
                        <div class="btnBox btnForm">
                            <button type="pay" class="header-btn b-element nodisplay">{{bookData.pcb[0]}}</button>
                            <button type="cancel" class="header-btn b-element" @click="cancelBooking">{{bookData.pcb[1]}}</button>
                            <button type="button" class="header-btn b-element" @click="goBack">{{bookData.pcb[2]}}</button>
                        </div>
                    </div>
                    <div v-else  class="bookHeader">
                        <p>{{ bookData.sent }}</p>
                        <img loading="lazy" src="@/assets/img/gif/ppc.gif" alt="Loading...">
                    </div>
                </div>
                <div v-else>
                    <h4 class="bookHeader">{{bookData.wannabook}}:</h4>
                    <div class="btnBox">
                        <span class="formText">{{bookData.roomName}}:<h4 class="red-text formText">{{ bookData.roomNames[selectedRoom] }}</h4></span>
                        <span class="formText">{{bookData.date}}: <p class="formData">{{ formatDate(selectedDate, 'short') }}</p></span>
                    </div>
                    <div class="btnBox">
                        <span class="formText">{{bookData.time}}: <p class="formData">{{ selectedSlot.start_time.slice(0, 5) }}</p></span>
                        <span class="formText">{{bookData.price}}: <p class="formData">{{ calculatedPrice }}&#8381;</p></span>
                    </div>
                    <div class="btnBox ">
                        <span class="formText fiveMan ">{{bookData.five}}:
                            <div>
                                <label class="toggle-switch form-data">
                                    <input type="checkbox" v-model="isFiveMan">
                                    <div class="toggle-switch-background">
                                        <div class="toggle-switch-handle"></div>
                                    </div>
                                </label>
                            </div>
                        </span>
                        <span class="formText animatorMan">{{bookData.animator}}:
                            <div>
                                <label class="toggle-switch form-data">
                                    <input type="checkbox" v-model="isAnimatorMan" @click="toggleCheckbox($event)" ref="animatorCheckbox">
                                    <div class="toggle-switch-background">
                                        <div class="toggle-switch-handle"></div>
                                    </div>
                                </label>
                            </div>
                        </span>
                        <span class="formText english ">{{bookData.english}}:
                            <div>
                                <label class="toggle-switch form-data">
                                    <input type="checkbox" v-model="isEnglish" @click="toggleCheckboxEng($event)" ref="englishCheckbox">
                                    <div class="toggle-switch-background">
                                        <div class="toggle-switch-handle"></div>
                                    </div>
                                </label>
                            </div>
                        </span>
                    </div>
                    <div v-if="isAnimatorTooShort || isEnglishTooShort" class="disclaimer animatordisclaimer">
                        <p v-if="isAnimatorTooShort" class="red-text">{{bookData.available}}</p>
                        <p v-if="isEnglishTooShort" class="red-text">{{bookData.availableeng}}</p>
                    </div>
                
                <form class="c-form">
                    <p class="iptBox">
                        <input type="name" class="b-element input" :placeholder="bookData.yourname" id="name" v-model="formData.name" @input="checkFormValidity" required>

                        <input
                        v-model="formData.phone"
                        type="tel"
                        id="phoneF"
                        class="b-element Input"
                        placeholder="+7(495)201-2536"
                        
                        @input="handlePhoneInput"
                        required
                    />
                    </p>
                    <p class="iptBox">
                        <input 
                        v-model="formData.email" 
                        type="email" 
                        id="emailF"
                        class="b-element Input" 
                        placeholder="email@email.ru" 
                        required
                        />
                        <input type="text" class="b-element input" :placeholder="bookData.promo" id="promo" v-model="formData.promo">
                    </p>
                    <div v-if="showPhoneError">
                        <span  class="red-text errorMSG">{{ bookData.contact[0] }}</span>
                    </div>
                    <div v-if="showEmailError">
                        <span  class="red-text errorMSG">{{ bookData.contact[1] }}</span>
                    </div>
                    <p class="btnBox btnForm">
                        <button
                            :disabled="!isFormValid"
                            class="header-btn"
                            :style="{ opacity: isFormValid ? 1 : 0.5 }"
                            :class="{ 'no-hover': !isFormValid }"
                            @mouseover="handleMouseOver"
                            @mouseout="handleMouseOut"
                            @click="submitBookingForm"
                        >
                            {{ bookData.book }}
                        </button>

                        <button type="button" class="header-btn b-element" @click="goBack">{{bookData.choosea}}</button>
                    </p>
                </form>
                </div>
            </div>
        <div class="disclaimer">
          <h5>{{bookData.disclaimer[0]}}</h5>
          <h6>{{bookData.disclaimer[1]}}</h6>
          <h6>{{bookData.disclaimer[2]}}</h6>
          <h6>{{bookData.disclaimer[3]}}</h6>
        </div>
    </section>
  </template>
  
  <script>
  import { MD5 } from 'crypto-js';
  import book from "@/db/book.json";
  
  export default {
    data() {
      return {
        bookData: book.ru,
        isLoadingProblem: false,
        isFirstLoad: true,
        showAllSlots: false,
        isFiveMan: false,
        isAnimatorMan: false,
        isEnglish: false,
        isAnimatorTooShort: false,
        isEnglishTooShort: false,
        activeDate: null,
        selectedDate: null,
        selectedRoom: null,
        selectedSlot: null,
        cancelSlot: false,
        isDataLoading: true,
        fiveManPrice: 0,
        animatorPrice: 0,
        data: {},
        rooms: [],
        dates: [],
        showForm: false,
        showErrorMessage: false,
        showSuссessMessage: false,
        showCancellationMessage : false,
        showCancellationError: false,
        isFormDisabled: true,      
        isFormLoading: false,      
        formData: {
            name: '',
            email: '',
            phone: '',
            promo: '',
        },
        isEmailValid: true,
        isPhoneValid: true,
        showEmailError: false, 
        showPhoneError: false,  
        changeData: false,
      };
    },
    methods: {
        checkFormValidity() {
            this.isFormDisabled = !(this.formData.name && this.formData.email && this.formData.phone.length >= 15);
        },
        toggleCheckbox(event) {
            const currentTime = new Date();
            const selectedSlotTime = new Date(this.selectedDate + 'T' + this.selectedSlot.start_time);
            const timeDifference = selectedSlotTime - currentTime;
            
            if (timeDifference < 86400000) {
                // Осталось менее 24 часов до сеанса
                this.isAnimatorTooShort = true;
                if (event.target === this.$refs.animatorCheckbox) {
                    this.isAnimatorMan = true; 
                    setTimeout(() => {
                        this.isAnimatorMan = false;
                    }, 300);
                    setTimeout(() => {
                        this.isAnimatorTooShort = false;
                        this.isEnglishTooShort = false;
                    }, 8000);
                }
            }
            else {
                this.isAnimatorMan = true; 
                this.isAnimatorTooShort = false;
            }
        },
        toggleCheckboxEng(event) {
            const currentTime = new Date();
            const selectedSlotTime = new Date(this.selectedDate + 'T' + this.selectedSlot.start_time);
            const timeDifference = selectedSlotTime - currentTime;

            if (timeDifference < 86400000) {
                this.isEnglishTooShort = true;
                if (event.target === this.$refs.englishCheckbox) {
                    this.isEnglish = true;
                    setTimeout(() => {
                        this.isEnglish = false;
                    }, 300);
                    setTimeout(() => {
                        this.isAnimatorTooShort = false;
                        this.isEnglishTooShort = false;
                    }, 8000);
                }
            } else {
                this.isEnglish = true;
                this.isEnglishTooShort = false;
            }
        },
        handleMouseOver() {
            if (this.$refs.btn) {
            this.$refs.btn.style.opacity = 0.5;
            }
        },
        handleMouseOut() {
            if (this.$refs.btn) {
            this.$refs.btn.style.opacity = 1;
            }
        },
        handlePhoneInput(event) {
            let rawPhone = this.formData.phone.replace(/\D/g, '');
            if (/^[78]/.test(rawPhone)) {
                rawPhone = '7' + rawPhone.slice(1);
            } else {
                rawPhone = '7' + rawPhone;
            }
            let formattedPhone = '+';
            if (rawPhone.length > 0) {
                formattedPhone += rawPhone[0];
            }
            if (rawPhone.length > 1) {
                formattedPhone += '(' + rawPhone.substring(1, 4);
            }
            if (rawPhone.length > 4) {
                formattedPhone += ')' + rawPhone.substring(4, 7);
            }
            if (rawPhone.length > 7) {
                formattedPhone += '-' + rawPhone.substring(7, 9);
            }
            if (rawPhone.length > 9) {
                formattedPhone += '-' + rawPhone.substring(9, 11);
            }
            if (event && event.inputType === 'deleteContentBackward') {
                rawPhone = rawPhone.slice(0, -1);
                if (rawPhone == 0){
                    formattedPhone = '';
                }
            }
            this.formData.phone = formattedPhone;
        },
        handleSlideChange(event) {
            this.selectDate(event.realIndex);
        },
        toggleMoreSlots() {
            this.showAllSlots = !this.showAllSlots;
            if (this.showAllSlots === false) {
            this.sectionmove(true);
            }
        },
        sectionmove(sectionx) {
            if (window.innerWidth < 800 & !sectionx) {
                const sectionHeading = document.getElementById('bookSystem');
                sectionHeading.scrollIntoView({ behavior: 'smooth' });
            } else {
                const sectionHeading = document.getElementById('section-heading');
                sectionHeading.scrollIntoView({ behavior: 'smooth' });
            }
            document.body.style.zoom = '100%';
        },
        selectDate(index) {
            localStorage.setItem('selectedDate', JSON.stringify(this.dates[index]));
            this.activeDate = index;
            this.selectedDate = this.dates[index];
            this.selectedRoom = null;
            this.selectedSlot = null;
            this.showForm = false;
        },
        showBookingForm(room, date, slot) {
            if (slot.status !== 'occupied') {
                this.selectedRoom = room;
                this.selectedDate = date;
                this.selectedSlot = slot;
                this.showForm = true;
                this.sectionmove(false);
                
                // Обновляем activeDate на индекс выбранной даты
                const dateIndex = this.dates.indexOf(date);
                if (dateIndex !== -1) {
                    this.activeDate = dateIndex;
                    localStorage.setItem('activeDate', this.activeDate);
                }
                
                // Сохраняем выбранную дату в localStorage
                localStorage.setItem('selectedDate', this.selectedDate);
            }
        },
        formatDate(date, wLong) {
            const options = { day: 'numeric', month: 'long', weekday: wLong };
            return new Date(date).toLocaleDateString(this.bookData.locale, options);
        },
        goBack() {
            this.selectedSlot = null;
            this.isAnimatorMan = false;
            this.isFiveMan = false;
            this.isEnglish = false;
            this.showErrorMessage = false;
            this.showEmailError = false;
            this.showPhoneError = false;
            this.showSuссessMessage = false;
            this.isFormLoading = false;
            this.cancelSlot = false;
            this.showCancellationMessage = false;
            this.showCancellationError = false;
            this.formData = {
            name: '',
            phone: '',
            email: '',
            };
            this.sectionmove(true);
        },
        submitBookingForm(event) {
            // console.log(this.selectedRoom);
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const phoneRegex = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;
            this.isEmailValid = emailRegex.test(this.formData.email);
            this.showEmailError = !this.isEmailValid && this.formData.email.length > 0;
            this.isPhoneValid = phoneRegex.test(this.formData.phone);
            this.showPhoneError = !this.isPhoneValid && this.formData.phone.length > 0;
            event.preventDefault();
            if (this.isPhoneValid && this.isEmailValid && this.isFormValid) {
            this.isFormLoading = true;
            } else {
            return;
            }

            // Calculate MD5 based on the entered values
            const md5 = this.calculateMD5(this.formData.name, 'no', this.formData.phone.replace(/\D/g, ''), this.formData.email);
            this.price = this.selectedSlot.price;
            if (this.isFiveMan) {
                this.price *= 1.2;
            }
            if (this.isAnimatorMan) {
                this.price += 1500;
            }
            if (this.isEnglish) {
                this.price += 1500;
            }
            const bookingData = new URLSearchParams();
            bookingData.append('first_name', this.formData.name);
            bookingData.append('family_name', 'no'); // или укажите значение
            bookingData.append('phone', this.formData.phone.replace(/\D/g, ''));
            bookingData.append('email', this.formData.email);
            bookingData.append('source', 'cubiculum.ru');
            bookingData.append('md5', md5);
            bookingData.append('date', this.selectedDate);
            bookingData.append('time', this.selectedSlot.start_time.slice(0, 5));
            bookingData.append('comment', this.formData.promo);
            bookingData.append('animator', this.isAnimatorMan);
            bookingData.append('five', this.isFiveMan);
            bookingData.append('english', this.isEnglish);
            // bookingData.append('price', this.price);

            fetch(`https://cubiculum.ru/api/quests/${this.selectedRoom}/order`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: bookingData
            })
            .then(response => {
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(response => {
                if (response.success) {
                    this.changeData = false;
                    this.showForm = false;
                    this.showSuссessMessage = true;
                    this.sectionmove(false);
                    const bookingDetails = response.data[0];
                    this.bookingDetails = {
                        record_id: bookingDetails.record_id,
                        record_hash: bookingDetails.record_hash
                    };
                    const slot = this.data[this.selectedRoom][this.selectedDate][this.selectedSlot.start_time];
                if (slot) {
                    slot.status = 'occupied';
                }
                } else {
                if (response.meta && response.meta.message && response.meta.message.includes('Услуга недоступна в выбранное время.')) {
                    this.showErrorMessage = true;
                    this.bookData.errorMessage = this.bookData.errorMessage1;
                    this.fetchData();
                }
                else {
                    this.showErrorMessage = true;
                    this.bookData.errorMessage = this.bookData.errorMessage2
                }
                }
            })
            .catch(error => {
                console.error('Error sending the request', error);
                this.showErrorMessage = true;
                this.bookData.errorMessage = this.bookData.errorMessage3
            });
        },
        goChange() {
            this.changeData = true;
        },
        submitBookingDataChange(event) {
            event.preventDefault();
            // console.log(this.formData.phone);
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const phoneRegex = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;
            this.isEmailValid = emailRegex.test(this.formData.email);
            this.showEmailError = !this.isEmailValid && this.formData.email.length > 0;
            this.isPhoneValid = phoneRegex.test(this.formData.phone);
            this.showPhoneError = !this.isPhoneValid && this.formData.phone.length > 0;

            if (this.isPhoneValid && this.isEmailValid && this.isFormValid) {
                this.isFormLoading = true;
            } else {
                return;
            }

            const bookingDataChange = {
                id: this.bookingDetails.record_id,
                name: this.formData.name,
                phone: this.formData.phone.replace(/\D/g, ''),
                email: this.formData.email
            };

            // console.log("Sending booking data change:", bookingDataChange);

            fetch(`https://cubiculum.ru/api/quests/change`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bookingDataChange)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(response => {
                if (response.success) {
                    this.changeData = false;
                    this.showForm = false;
                    this.showSuсcessMessage = true;
                }
            })
            .catch(error => {
                console.error('Error sending the request', error);
                this.showErrorMessage = true;
                this.bookData.errorMessage = this.bookData.errorMessage3;
            });
},
        calculateMD5(firstName, familyName, phone, email) {
            const md5String = firstName + familyName + phone + email;
            const md5 = MD5(md5String).toString();
            return md5;
        },
        cancelBooking() {
            this.cancelSlot = true;
            this.isFormLoading = false;
            this.selectedSlot = false;
            this.showSuссessMessage = false;

            if (!this.bookingDetails) {
                return; 
            }

            const { record_id, record_hash } = this.bookingDetails;
            fetch(`https://cubiculum.ru/api/quests/cancel/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: record_id, hash: record_hash })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(response => {
                // console.log(response);
                this.bookingDetails = null;
                this.isFormLoading = false;
                this.fetchData();
                if (response.success) {
                    this.showCancellationMessage = true;
                } else {
                    this.showCancellationError = true;
                }
            })
            .catch(error => {
                console.error('Error canceling the booking', error);
                this.fetchData();
            });
        },
        goUpdate() {
            this.isLoadingProblem = false;
            this.isDataLoading = true;
            this.fetchData();
        },
        setActiveDate() {
            if (this.selectedDate) {
                // Если уже есть выбранная дата, не изменяем ее
                return;
            }

            const moscowTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Moscow" });
            const serverTime = new Date(moscowTime);
            const currentHour = serverTime.getHours();

            if (currentHour >= 23) {
                this.selectedDate = this.dates[1]; // Устанавливаем следующую дату
                this.activeDate = 1;
            } else {
                this.selectedDate = this.dates[0]; // Устанавливаем текущую дату
                this.activeDate = 0;
            }


            // Сохраняем выбранные даты в localStorage для последующего использования
            localStorage.setItem('selectedDate', this.selectedDate);
            localStorage.setItem('activeDate', this.activeDate);
        },
        fetchData() {
            fetch('https://cubiculum.ru/apiv2/quests/timetable/0', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                this.data = data;
                this.rooms = Object.keys(this.data);
                this.dates = Object.keys(this.data[this.rooms[0]]);
                this.isDataLoading = false;

                if (this.isFirstLoad) {
                    // Первая загрузка: устанавливаем активную дату
                    this.setActiveDate();
                    this.isFirstLoad = false;
                } else {
                    // Проверяем, есть ли сохраненные значения в localStorage
                    const savedDate = localStorage.getItem('selectedDate');
                    const activeDateIndex = localStorage.getItem('activeDate');

                    // Если сохраненные значения валидны, восстанавливаем их
                    if (savedDate && this.dates.includes(savedDate)) {
                        this.selectedDate = savedDate;
                        this.activeDate = activeDateIndex ? parseInt(activeDateIndex) : 0;
                    } else {
                        // Если сохраненные значения не валидны, используем логику setActiveDate
                        this.setActiveDate();
                    }
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                this.isDataLoading = false;
                this.isLoadingProblem = true;
            });
        },
    },
    mounted() {
        this.bookData = this.en ? book.en : book.ru;
        this.fetchData();
        this.interval = setInterval(this.fetchData, 30000);
        const savedDate = localStorage.getItem('selectedDate');
        if (savedDate && this.dates.includes(savedDate)) {
            this.selectedDate = savedDate;
        }
        },
        beforeUnmount() {
        clearInterval(this.interval);
    },
    props: {
        quest: {
            type: String,
            default: 'all'
        },
        birthday: {
            type: Boolean,
            default: true
        },
        en: {
            type: Boolean,
            default: false
        }
    },
    computed: {
    calculatedPrice() {
      let price = this.selectedSlot.price;
      if (this.isFiveMan) {
        price *= 1.2;
      }
      if (this.isAnimatorMan) {
        price += 1500;
      }
      if (this.isEnglish) {
        price += 1500;
      }
      return price;
    },
    formattedPhone() {
      let phone = this.formData.phone;
      return phone.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2}).*/, '+$1($2)$3-$4-$5');
    },
    moreDates() {
      return this.data[this.quest] ? Object.keys(this.data[this.quest]).length > 6 : false;
    },
    isFormValid() {
        return (
            this.formData.name !== '' &&
            this.formData.phone !== '' &&
            this.formData.email !== '' 
        )
    },
  },
  };
</script>

<style scoped>
h3{
    text-transform: uppercase;
}
h6{
    font-weight: 400;
    font-size: 0.9rem;
    margin: 0.3rem;
}
/* DATES */
.links, .btn-quest{
    display: flex;
    margin: 0 0.5rem;
    align-items: center;
    justify-content: space-between;
}
.grid-dates{
    display: flexbox;
    margin: 0 2%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding: 0 3%;
}
.grid-dates::-webkit-scrollbar {
  display: none;
}
.grid-dates button, #slideDate{
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    list-style: none;
    align-self: center;
    border: thin #C4E0E5;
    margin: 2% 5px;
    min-height: 65px;
    min-width: 65px;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    background-color: #f5f5f5;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    transition: all .4s ease-in-out;
}
.grid-dates button:hover {
    background-color: #C4E0E5;
}
.grid-dates button *, #slideDate *{
    font-weight: 400;
    font-size: 0.7rem;
    text-transform: uppercase;
    margin: 0 auto;
}
.isActive {
    background-color: #d9e6e8!important;
    cursor: default;
}
.isActive *{
    cursor: default;
}
/* SLOTS */
.slot{
    margin: 1rem 0.5rem;
}
.quest-name{
    display: block!important;
    position: absolute;
    text-align: center;
    font-size: 0.8rem;
    margin-top: -7px;
    background-color: #fff;
    margin-left: 2rem;
}
.btn-quest{
    margin: 0.2rem;
    border: dotted 0.8px #739ba1;
    border-radius: 20px;
    justify-content: space-evenly;
    align-items: center!important;
}
.btn-quest button{
    align-items: space-between;
    background-color: #fff;
    border: thin dotted #C4E0E5;
    margin: 1rem 0;
    height: 65px;
    width: 65px;
    border-radius: 20%;
    padding: 1%;
}
.btn-quest button *{
    font-size: 0.7rem;
}
.btn-quest button{
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    transition: all .3s ease-in-out;
}
.grid-quest button:hover{
    background: #C4E0E5;
}
.grid-quest button:active{
    background: #C4E0E5;
    border: thin dotted #C4E0E5!important;
}
.not-free, .not-free *{
    cursor: default!important;
    box-shadow: none!important;
    color: #abb8ba!important;
}
.not-free{
    border: thin dotted #abb8ba!important;
    background: none!important;
}
.not-free:hover{
    background: none!important;
}

/* DISCLAIMENR */
.disclaimer{
    display: flexbox;
    text-align: center;
    margin: 2rem 15%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding: 1rem;
    border-radius: 20px;
}
/* BOOKING FORM */
.bookingForm{
    display: flexbox;
    text-transform: uppercase;
    margin: 2rem 20%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    padding: 1rem;
    border-radius: 20px;
    border-radius: 20px;
    padding: 2rem 3rem;
    transition: all .4s ease-in-out;
}
.bookHeader{
    display: grid;
    gap: 0.2rem;
    text-align: center;
    place-items: center;
}
.c-form{
    display: flexbox;
    padding: 5%;
}
.b-element:not(.header-btn), textarea{
    width: 100%; 
    height: 40px; 
    margin: 0.4rem 0; 
    border: 1px solid rgb(208, 211, 219); 
    background: none; 
    border-radius: 15px; 
    font-size: 0.8rem;
    font-weight: 400; 
    padding: 0px 0px 0px 15px; 
    transition: border-color 200ms ease 0s;
}
.btnBox{
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
    gap: 1rem;
}
.formData{
    font-weight: 500;
}
.formText{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: calc(100% - 1px); 
    text-align: center;
}
.animatordisclaimer{
    margin: 0 3% -2rem 3%;
    text-transform: none!important;
}
/* BUTTON FORM */
.header-btn{
      display: inline-block;
      padding: 6px 10px;
      color: #739ba1;
      border: 1px solid #739ba1;
      background-color: #fff;
      border-radius: 3px;
      font-size: 0.8rem;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      transition: all .4s ease-in-out;
      cursor: pointer!important;
  }
  .header-btn:hover{
      scale: 1.04;
  }

/* BUTTON TOGGLE */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-handle {
  position: absolute;
  top: 2px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  top: -20px;
  right: -20px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
  transform: translateX(20px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #05c46b;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: #05c46b;
  box-shadow: inset 0 0 0 2px #04b360;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
  content: "On";
  color: #05c46b;
  right: -15px;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background .toggle-switch-handle {
  transform: translateX(25px);
}
/* ONLY ONEQUEST */
.roomName{
    text-align: center;
}
.hideIndex {
  display: none;
}
.show-more-btn{
    text-align: center;
}
/* BUTTON */
.disabled {
   cursor: not-allowed!important;
}
.disabled:hover {
    scale: 1; 
}
/* LOADER */
.l-element {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column!important;
    margin: 1.2rem;
}
/* Loader */
.loaderB-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 200px; /* или любая другая подходящая высота */
}

/* .loaderB {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
} */
.no-hover:hover {
  scale: 1;
}
.no-hover{
    cursor: not-allowed!important;
}
.errorMSG{
    font-size: 0.8rem;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* RESPONSIVE */
@media (min-width: 1350px){
    .booking{
        padding: 0 2rem;
    }
}
@media (max-width: 950px){
    .grid-dates{
        display: flex;
        margin: 0;
        overflow-x: auto;
    }
    .btn-quest{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        padding: 5%;
    }
    .btn-quest button{
        margin: 5% auto;
    }
    #slideDate *{
        font-weight: 600!important;
    }
    .date-swipe{
        font-size: 1.2rem!important;
    }
    .quest-name{
        flex: 0 1 100%;
        text-align: center;
        max-width: 90%;
    }
    /* FORM */
    .bookingForm{
        margin: 2rem 10%;
    }
    .formText{
        width: auto;
        justify-content: first baseline;
        gap: 1rem;
    }
    .bookHeader h4:not(.red-text){
        margin-bottom: 1rem;
    }
    .btnBox{
        flex-wrap: wrap;
    }
}
@media (max-width: 830px){
    .formText{
        font-size: 0.8rem;
    }
    .quest-name{
        font-size: 1rem;
    }
}
@media (max-width: 480px){
    .btn-quest, .grid-dates{
        grid-template-columns: repeat(4, 1fr);
    }
    .bookingForm{
        margin: 2rem 5%;
    }
}
@media (max-width: 350px){
    .btn-quest, .grid-dates{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 250px){
    .btn-ques, .grid-dates{
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
