<template>
  <section id="testi">
    <div class="sec-head">
            <h2><p> БОЛЕЕ <span class="red-text"> 2000 ВОСТОРЖЕННЫХ ОТЗЫВОВ </span> ОТ НАШИХ ИГРОКОВ</p></h2>
        </div>
        <div class="t-box">
            <div class="t-item">
                <div class="t-icon">
                    <a href="https://www.tripadvisor.ru/Attraction_Review-g298484-d6901846-Reviews-Cubiculum-Moscow_Central_Russia.html" aria-label="Find us on Tripadvisor"><img loading="lazy" src="../assets/img/TestiTripadvisor.png" alt="Tripadvisor reviews certificate travellers choice" id="t-icon"></a>
                </div>
                <div class="t-text">
                    №1 В рейтинге игровых развлечений Москвы на портале Tripadvisor
                </div>
            </div>
            <div class="t-item">
                <div class="t-icon">
                    <a href="https://yandex.ru/maps/-/CCUceDE3tD" aria-label="Find us on Yandex.Maps" target="_blank"><img loading="lazy" src="../assets/img/TestiYandex.png" alt="Yandex.Maps Goodplace" id="t-icon"></a>
                </div>
                <div class="t-text">
                    Ежегодные сертификаты «Хорошее место» от Яндекс.Карт
                </div>
            </div>
            <div class="t-item">
                <div class="t-icon">
                    <a href="https://goo.gl/maps/g1NBkfcrJ5wunEVk6" aria-label="Find us on Google.Maps"><img loading="lazy" src="../assets/img/TestiGoogle.png" alt="Google.Maps reviews" id="t-icon"></a>
                </div>
                <div class="t-text">
                    4.99 – наш рейтинг на Гугл.Картах
                </div>       
            </div>
        </div>
    <div id="Carousel-cont">
      <Carousel3d :height="sHight" :width="sWidth" class="Carousel">
        <slide v-for="(slide, index) in filteredReviews" :key="index" :index="index" class="r-slide">
          <div class="r-header">
            <div class="stars">
              ★★★★★
            </div>
            <div class="r-name">
              {{ slide.name }}
            </div>
            <div class="r-quest">
              {{ slide.quest }}
            </div>
          </div>
          <div class="t-review" >{{ slide.review }}</div>
        </slide>
      </Carousel3d>
    </div>
  </section>
</template>

<script>
import { Carousel3d, Slide } from 'vue3-carousel-3d';
import "vue3-carousel-3d/dist/index.css";
import testimonials from "@/db/testimonials.json";

export default {
  data() {
    return {
      sWidth: 450,
      sHight: 320
    };
  },
  props: {
    questType: {
      type: Number,
      default: null
    }
  },
  components: {
    Carousel3d,
    Slide
  },
  computed: {
  filteredReviews() {
    const ruTestimonials = testimonials.RU;
    const shuffledReviews = [];

    // Если questType не передан, отображаем все отзывы
    if (this.questType === null) {
      for (const questType in ruTestimonials) {
        if (Object.prototype.hasOwnProperty.call(ruTestimonials, questType)) {
          const reviewsForQuest = ruTestimonials[questType];
          for (const review of reviewsForQuest) {
            shuffledReviews.push({
              name: review.name,
              quest: this.getQuestName(parseInt(questType)), // Преобразуем questType к числовому типу данных
              review: review.review,
            });
          }
        }
      }
    } else {
      // Проверяем, есть ли отзывы для указанного квеста
      if (Object.prototype.hasOwnProperty.call(ruTestimonials, this.questType)) {
        const reviewsForQuest = ruTestimonials[this.questType];
  
        for (const review of reviewsForQuest) {
          shuffledReviews.push({
            name: review.name,
            quest: this.getQuestName(this.questType),
            review: review.review,
          });
        }
      }
    }

    // Перемешиваем отзывы
    shuffledReviews.sort(() => Math.random() - 0.5);

    return shuffledReviews;
  }
},

  methods: {
    getQuestName(questType) {
      switch (questType) {
        case 1: // Приведено к числовому типу данных
          return "Красная кнопка";
        case 2: // Приведено к числовому типу данных
          return "Карты деньги два стола";
        case 6: // Приведено к числовому типу данных
          return "Последний пепелац";
        default:
          return "***";
      }
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      if (window.innerWidth < 1000) {
        if (window.innerWidth < 800) {
          if (window.innerWidth < 500) {
            this.sWidth = 300;
            this.sHight = 370;
          } else {
            this.sWidth = 400;
            this.sHight = 320;
          }
        } else {
          this.sWidth = 450;
          this.sHight = 320;
        }
      } else {
        this.sWidth = 500;
        this.sHight = 320;
      }
    },
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
  },
};
</script>

<style scoped>
/* MAIN */
#testi{
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
}
.t-box{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 20px auto;
}
.t-item{
    display: grid;
    grid-template-rows: repeat(2, auto);
    padding: 5%;
}
.t-icon{
    transition: all .4s ease-in-out;
}
.t-icon:hover{
    scale: 1.02;
}
/* SLIDER */
.r-slide{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
  font-size: 0.75rem;
  border: thin;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  padding: 5%;
  text-align: center;
  cursor: pointer;
}
.r-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stars {
  align-items: center;
  color: gold!important;
  display: flex;
  justify-content: center;
}
.carousel-3d-container{
  height: 342px!important;
}

@media (max-width: 760px){
    .t-box{
        grid-template-columns: 1fr;
    }
    #t-icon{
        min-height: 100px;
        max-height: 150px;
    }
    .r-slide{
      max-height: 400px;
    }
    .t-review {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 18; /* Ограничение на количество строк */
      -webkit-box-orient: vertical;
      text-overflow: ellipsis; /* Добавляем три точки в конце обрезанного текста */
    }
}
@media (max-width: 500px){
  .carousel-3d-container{
  height: 392px!important;
}
}
</style>