import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    behavior: 'smooth',
    meta: { 
      title: 'Квесты Кубикулум: Захватывающие квесты в Москве! - официальный сайт',
      description: 'Ищете увлекательный и необычный способ провести время? Приходите в Кубикулум - мир захватывающих квестов! Вас ждут 3 увлекательных сюжета на любой вкус.',
      keywords: 'квест, Москва, приключение, тимбилдинг, день рождения, подарок, красная кнопка, Последний пепелац, Карты деньги два стола'
    },
    component: HomeView
  },
  {
    path: '/quest-red-button',
    name: 'red-button',
    behavior: 'smooth',
    meta: {
      title: "Квест в реальности: Красная кнопка - Спасите мир от ядерной катастрофы!",
      description: "Хотите испытать себя в роли секретного агента и предотвратить запуск ядерных ракет? Квест 'Красная кнопка' в Кубикулум на Дизайн Заводе - ваш шанс! Вас ждет захватывающая атмосфера, напряженные задачи и командная игра.",
      keywords: "квест, реальный квест, красная кнопка, спасти мир, ядерная катастрофа, Дизайн Завод, Cubiсulum, Москва, Дмитровская, секретный агент, командная игра, приключение, игра, развлечение"
    },
    component: () => import('../views/RedButton.vue')
  },
  {
    path: '/quest-lock-stock-and-two-playing-tables',
    name: 'LockStockAndTwoPlayingTables',
    behavior: 'smooth',
    meta: {
      title: "Квест в реальности: Карты, деньги, два стола - Обыграйте подпольное казино!",
      description: "Хотите испытать себя в роли мошенника и обыграть подпольное казино? Квест 'Карты, деньги, два стола' в Кубикулум на Дизайн Заводе - ваш шанс! Вас ждет расслабленная атмосфера, логические задачи и атмосфера азарта.",
      keywords: "квест, реальный квест, карты деньги два стола, подпольное казино, азарт, логические задачи, Дизайн Завод, Cubiculum, Москва, Дмитровская, мошенник, игра, развлечение"
    },
    component: () => import('../views/KDDS.vue')
  },
  {
    path: '/quest-pepelac',
    name: 'Pepelac',
    behavior: 'smooth',
    meta: {
      title: "Квест в реальности: Последний Пепелац - Угони космический корабль!",
      description: "Хотите испытать себя в роли космического пирата и угнать ржавый космический корабль 'Пепелац'? Квест 'Последний Пепелац' в Кубикулум на Дизайн Заводе - ваш шанс! Вас ждет атмосфера стимпанк-эпохи, логические задачи, спецэффекты и командное взаимодействие.",
      keywords: "квест, реальный квест, Последний Пепелац, космический корабль, стимпанк, логические задачи, спецэффекты, командное взаимодействие, Дизайн Завод, Cubiculum, Москва, Дмитровская"
    },
    component: () => import('../views/LastPepelac.vue')
  },
  {
    path: '/partners',
    name: 'Partners',
    behavior: 'smooth',
    meta: {
      title: "Наши партнеры - Кубикулум",
      description: "Ознакомьтесь с нашими партнерами, которые поддерживают Кубикулум, квесты в реальности в Москве.",
      keywords: "Кубикулум, квест, реальный квест, Москва, партнеры, логотипы"
    },
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/legal',
    name: 'Legal Ru',
    behavior: 'smooth',
    meta: {
      title: "Правовая информация - Кубикулум",
      description: "Ознакомьтесь с правилами и условиями Кубикулум, квестов в реальности в Москве, включая правила бронирования, возврата денег, поведения на территории и обработки персональных данных.",
      keywords: "Кубикулум, квест, реальный квест, Москва, правила, бронирование, возврат, поведение, персональные данные"
    },
    component: () => import('../views/LegalRu.vue')
  },
  {
    path: '/quest-english',
    name: 'English',
    behavior: 'smooth',
    meta: { 
      title: 'Cubiculum: Escape Rooms in Moscow - Official Website',
      description: "Embark on an exciting adventure at Cubiculum, Moscow's premier escape room destination. Choose from 3 thrilling stories, perfect for any group.",
      keywords: 'escape room, Moscow, adventure, team building, birthday, gift, Cubiсulum, red button, lock stock and two playing tables, the last spaceship Pepelac'
    },
    component: () => import('../views/HomeViewEn.vue')
  },
  {
    path: '/quest-gift-certificate',
    name: 'gift-certificate',
    behavior: 'smooth',
    meta: {
      title: "Подарочный сертификат на квест Кубикулум - оригинальный подарок на любой праздник!",
      description: "Ищете уникальный подарок на день рождения, Новый год, годовщину или просто так? Подарочный сертификат на квест в Cubikulum - это беспроигрышный вариант! Увлекательное приключение, новые впечатления и яркие эмоции гарантированы.",
      keywords: "подарочный сертификат, квест, подарок, день рождения, Новый год, годовщина, Cubiсulum, Москва, Дмитровская, Дизайн Завод, Красная кнопка, Последний пепелац, Карты деньги два стола, впечатления, эмоции, приключение"
    },
    component: () => import('../views/GiftCertificate.vue')
  },
  {
    path: '/quest-corporate-party',
    name: 'quest-corporate-party',
    behavior: 'smooth',
    meta: {
      title: "Квест на корпоратив на Дизайн Заводе - лучшая идея для корпоратива!",
      description: "Ищете оригинальную и незабываемую идею для корпоратива? Квест в Кубикулум на Дизайн Заводе - это идеальное решение! Захватывающее приключение, командное взаимодействие и море позитивных эмоций гарантированы.",
      keywords: "квест на корпоратив, корпоратив, квест, Дизайн Завод, Кубикулум, Cubiсulum, Москва, Дмитровская, командное взаимодействие, тимбилдинг, приключение, игра, развлечение"
    },
    component: () => import('../views/CorporateParty.vue')
  },
  {
    path: '/quest-birthday-party',
    name: 'quest-birthday-party',
    behavior: 'smooth',
    meta: {
      title: "День рождения в квестах - незабываемые приключения в Кубикулум!",
      description: "Ищете оригинальный и незабываемый способ отпраздновать день рождения? Квесты Кубикулум на Дизайн Заводе (Дмитровская) - это идеальное место для веселого и захватывающего праздника!",
      keywords: "День рождения в квестах, Квесты на день рождения, Квесты для детей, Квесты для взрослых, Корпоративы в квестах, Квесты на Дизайн Заводе, Квесты Дмитровская, Квесты Кубикулум, Захватывающие приключения, Незабываемые эмоции, Подарки на день рождения, Рестораны, Детские дни рождения, Подарочные сертификаты, Квесты на английском языке"
    },
    component: () => import('../views/BirthdayParty.vue')
  },
  //english
  {
    path: '/escape-room-red-button',
    name: 'Red Button Quest English',
    behavior: 'smooth',
    meta: {
      title: "Real-Life Escape Room: Red Button - Save the World from Nuclear Catastrophe!",
      description: "Want to test yourself as a secret agent and prevent the launch of nuclear missiles? The 'Red Button' escape room at Cubikulum on Design Depot is your chance! An exciting atmosphere, intense tasks, and team play await you.",
      keywords: "escape room, real-life escape room, red button, save the world, nuclear catastrophe, Design Depot, Cubiculum, Moscow, Dmitrovskaya, secret agent, team play, adventure, game, entertainment"
    },
    component: () => import('../views/RedButtonEn.vue')
  },
  {
    path: '/escape-room-lock-stock-and-two-playing-tables',
    name: 'Lock Stock And Two Playing Tables Escape room English',
    behavior: 'smooth',
    meta: {
      title: "Real-Life Escape Room: Lock Stock And Two Playing Tables - Beat the Underground Casino!",
      description: "Want to test yourself as a cheater and beat the underground casino? The 'Lock Stock And Two Playing Tables' escape room at Cubiculum on Design Depot is your chance! A relaxed atmosphere, logic puzzles, and an atmosphere of excitement await you.",
      keywords: "escape room, real-life escape room, cards money two tables, underground casino, gambling, logic puzzles, Design Depot, Cubiculum, Moscow, Dmitrovskaya, cheater, game, entertainment"
    },
    component: () => import('../views/KDDSEn.vue')
  },
  {
    path: '/escape-room-pepelac',
    name: 'Pepelac Quest English',
    behavior: 'smooth',
    meta: {
      title: "Real-Life Escape Room: The Last Spaceship - Steal the Spaceship!",
      description: "Want to test yourself as a space pirate and steal the rusty spaceship 'The Last Spaceship'? The 'The Last Spaceship' escape room at Cubiculum on Design Depot is your chance! A steampunk atmosphere, logic puzzles, special effects and teamwork await you.",
      keywords: "escape room, real-life escape room, The Last Spaceship, spaceship, steampunk, logic puzzles, special effects, teamwork, Design Depot, Cubiculum, Moscow, Dmitrovskaya"
    },
    component: () => import('../views/LastPepelacEn.vue')
  },
  {
    path: '/legal-english',
    name: 'Legal',
    behavior: 'smooth',
    meta: 
    {
      title: "Legal Information - Cubiculum",
      description: "Review the rules and terms of Cubiculum, real-life escape rooms in Moscow, including booking, refund, behavior on the premises, and personal data processing.",
      keywords: "Cubiculum, quest, real-life quest, Moscow, rules, booking, refund, behavior, personal data"
    },
    component: () => import('../views/LegalEn.vue')
  },
  {
    path: '/escape-room-gift-certificate',
    name: 'gift-certificate-english',
    behavior: 'smooth',
    meta: {
      title: "Gift Certificate for an Escape Room Adventure in Cubiculum - The Perfect Present for Any Occasion!",
      description: "Looking for a unique and unforgettable gift for a birthday, New Year's, anniversary, or just because? A gift certificate for an escape room adventure at Cubikulum is the perfect choice! An exciting adventure, new experiences, and unforgettable emotions are guaranteed.",
      keywords: "gift certificate, escape room, gift, birthday, New Year's, anniversary, Cubiсulum, Moscow, Dmitrovskaya, Design Depot, Red Button, Last Spaceship, Cards Money Two Tables, experiences, emotions, adventure"
    },
    component: () => import('../views/GiftCertificateEn.vue')
  },
  {
    path: '/escape-room-corporate-party',
    name: 'quest-corporate-party-english',
    behavior: 'smooth',
    meta: {
      title: "Escape Room Corporate Event at Design Depot - The Perfect Idea for Your Company Gathering!",
      description: "Looking for a unique and unforgettable way to celebrate your next corporate event? An escape room adventure at Cubikulum on Design Depot is the perfect choice! An exciting adventure, team bonding, and a ton of fun are guaranteed.",
      keywords: "corporate escape room, corporate event, escape room, Design Depot, Cubiсulum, Moscow, Dmitrovskaya, team building, team bonding, adventure, game, entertainment"
    },
    component: () => import('../views/CorporatePartyEn.vue')
  },
  {
    path: '/escape-room-birthday-party',
    name: 'quest-birthday-party-english',
    behavior: 'smooth',
    meta: {
      title: "Birthday Escape Rooms - Unforgettable Adventures at Cubiculum!",
      description: "Looking for an original and unforgettable way to celebrate your birthday? Cubikulum quests at Design Depot (Dmitrovskaya) are the perfect place for a fun and exciting party!",
      keywords: "Birthday Quests, Quests for Birthday, Quests for Children, Quests for Adults, Corporate Quests, Quests at Design Depot, Quests Dmitrovskaya, Quests Cubiculum, Exciting Adventures, Unforgettable Emotions, Birthday Gifts, Restaurants, Children's Birthday Parties, Gift Certificates, Quests in English"
    },
    component: () => import('../views/BirthdayPartyEn.vue')
  },
  {
    path: '/discounts',
    name: 'quest-discounts',
    behavior: 'smooth',
    meta: {
      title: "Скидки на квесты - Кубикулум",
      description: "Информация о текущих скидках и акциях на квесты Кубикулум в Москве.",
      keywords: "Кубикулум, квест, реальный квест, Москва, скидки, акции"
    },
    component: () => import('../views/Discounts.vue')
  },
  {
    // This route catches all undefined paths
    path: '/:pathMatch(.*)*', 
    component: () => import('../views/NotFound.vue') // Assign the NotFound.vue component to handle 404 errors
  },
  {
    path: '/client/order/:hash',
    name: 'Обработка бронирования',
    component: () => import('../views/OrderComponent.vue'),
    props: true
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  /* eslint-disable-next-line no-unused-vars */
  scrollBehavior(to, _from, _savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    } else {
      return { top: 0 };
    }
  }
});


export default router
