<template>
    <section id="callforaction">
        <div class="c-box">
            <div class="c-item">
            <h2 class="upText">{{ callText[0] }}</h2>
            </div>
            <div class="c-item">
                <a v-if="callText[2]" @click="navigateToPage('#contact-form')" class="header-btn">{{ callText[1] }}</a>
                <a v-else href="#book" class="header-btn">Забронировать</a>
            </div>
        </div>
    </section>
</template>
<script>
export default{
    name: 'CallFARu',
    props: {
        callText: {
            type: Array,
            default() {
                return [
                    "Уже решили? Выберите удобное время и забронируйте квест всего за 1 минуту!",
                    "Забронировать",
                    false
                ]
            } 
        },
    },
    methods: {
        navigateToPage(sectionId) {
            this.$router.push(sectionId);
        },
    },  
}
</script>
<style scoped>
/*CALL FOR*/
#callforaction{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5C258D;
    background: -webkit-linear-gradient(to right, #4389A2, #5C258D); 
    background: linear-gradient(to right, #4389A2, #5C258D); 
    margin: 2rem 2%;
    border-radius: 50px 0 50px 0;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.c-box{
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    padding: 5% 0;
    margin-top: 20px;
    gap: 2rem;
}
.c-item{
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
}
.c-item h2{
    color: #fff;
    margin: auto;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

a{
    text-decoration: none;
    color: #fff;
    font-size: 0.8rem;
}
@media (max-width: 815px){
    #callforaction{
        padding: 2rem;
    }
    .c-box{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
    }
    .header-btn a{
    margin: 2rem;
    }
}
</style>