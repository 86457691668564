<template>
<section id="footer">
    <div class="f-box">
        <div class="f-item">
            <ul v-if="fdata[2]">
                <li class="link head">
                    <h2>Escape rooms:</h2>
                </li>
                <li class="link">
                    <a href="/escape-room-red-button">Red button</a>
                </li>
                <li class="link">
                    <a href="/escape-room-pepelac">Pepelac (The Last Spaceship)</a>
                </li>
                <li class="link">
                    <a href="/escape-room-lock-stock-and-two-playing-tables">Lock Stock and Two Playing Tables</a>
                </li>
                <li class="link">
                    <br>
                    <a href="/escape-room-gift-certificate">Gift certificate</a>
                </li>
                <!-- <li class="link">
                    <a href="/quest-discounts">Скидки</a>
                </li> -->
            </ul>
            <ul v-else>
                <li class="link head">
                    <h2>Квесты:</h2>
                </li>
                <li class="link">
                    <a href="/quest-red-button">Красная кнопка</a>
                </li>
                <li class="link">
                    <a href="/quest-pepelac">Последний пепелац</a>
                </li>
                <li class="link">
                    <a href="/quest-lock-stock-and-two-playing-tables">Карты деньги два стола</a>
                </li>
                <li class="link">
                    <br>
                    <a href="/quest-gift-certificate">Подарочный сертификат</a>
                </li>
                <!-- <li class="link">
                    <a href="/quest-discounts">Скидки</a>
                </li> -->
            </ul>
        </div>
        <div class="f-item">
            <ul v-if="fdata[2]">
                <li class="link head">
                    <h2>SPECIAL OCCASIONS:</h2>
                </li>
                <li class="link">
                    <a href="/escape-room-birthday-party">BIRTHDAY PARTY</a>
                </li>
                <!-- <li class="link">
                    <a href="/quest-kids-birthday-party">Детский день рождения в квесте</a>
                </li> -->
                <li class="link">
                    <a href="/escape-room-corporate-party">CORPORATE PARTY</a>
                </li>
                <li class="link">
                    <br>
                    <a href="/">Escape rooms on russian</a>
                </li>
                <li class="link">
                    <a href="/quest-english">Main page</a>
                </li>
                <li class="link">
                    <a class="no-display" href="/quest-chinese">Escape room on chinese</a>
                </li>
            </ul>
            <ul v-else>
                <li class="link head">
                    <h2>События:</h2>
                </li>
                <li class="link">
                    <a href="/quest-birthday-party">Квест на день рождения</a>
                </li>
                <!-- <li class="link">
                    <a href="/quest-kids-birthday-party">Детский день рождения в квесте</a>
                </li> -->
                <li class="link">
                    <a href="/quest-corporate-party">Корпоратив в Квесте</a>
                </li>
                <li class="link">
                    <br>
                    <a href="/quest-english">Квест на английском</a>
                </li>
                <li class="link">
                    <a href="/">Главная</a>
                </li>
                <li class="link">
                    <a class="no-display" href="/quest-chinese">Квест на китайском</a>
                </li>
            </ul>
        </div>
        <div class="f-item">
            <ul v-if="fdata[2]">
                <li class="link head">
                    <h2>About:</h2>
                </li>
                <!-- <li class="link">
                    <a href="/quest-history">История квестов</a>
                </li> -->
                <li class="link no-display">
                    <a href="/partners">Partners</a>
                </li>
                <li class="link">
                    <a href="/legal-english">Legal information</a>
                </li>
                <li v-if="fdata[1]" class="link">
                    <a v-if="fdata[0]" :href="`#order`" @click="navigateToPage('#order')" class="header-btn btnFooter"> Order now </a>
                    <a v-else :href="`#book`" @click="navigateToPage('#book')" class="header-btn btnFooter"> Book now </a>
                </li>
                <li v-else>
                    <a :href="`#book`" @click="navigateToPage('#book')" class="header-btn btnFooter"> Book now </a>
                </li>
            </ul>
            <ul v-else>
                <li class="link head">
                    <h2>О нас:</h2>
                </li>
                <!-- <li class="link">
                    <a href="/quest-history">История квестов</a>
                </li> -->
                <li class="link">
                    <a href="/partners">Партнеры</a>
                </li>
                <li class="link">
                    <a href="/legal">Правовая информация</a>
                </li>
                <li v-if="fdata[1]" class="link">
                    <a v-if="fdata[0]" :href="`#order`" @click="navigateToPage('#order')" class="header-btn btnFooter"> Заказать </a>
                    <a v-else :href="`#book`" @click="navigateToPage('#book')" class="header-btn btnFooter"> Забронировать </a>
                </li>
                <li v-else>
                    <a :href="`#book`" @click="navigateToPage('#book')" class="header-btn btnFooter"> Забронировать </a>
                </li>
            </ul>
        </div>
    </div>
    <ul class="copyright">
        <li v-if="fdata[2]">
            <h5><i class="material-icons">copyright</i>Cubiculum 2014-{{ currentYear }}</h5>
        </li>
        <li v-else>
            <h5><i class="material-icons">copyright</i>Кубикулум 2014-{{ currentYear }}</h5>
        </li>
    </ul>
</section>
</template>    
<script>
export default {
    props: {
        fdata: {
            type: Array,
            default() {
                return [
                    false, //order
                    true, //nobook
                    false //language
                ]
            } 
        },
    },
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    },
    methods: {
    navigateToPage(sectionId) {
        this.$router.push(sectionId);
    },
  }
}
</script>
<style scoped>
#footer{
    background: #5f2c82;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #49a09d, #5f2c82);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #49a09d, #5f2c82); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 50px 0 50px 0;
    padding: 2% 5%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    text-transform: uppercase;
}
h2{
    justify-content: flex-start;
    color: #fff;
    font-size: 1.2rem;
}
h5{
    color: #fff;
    font-size: 0.8rem;
}
.material-icons{
    color: #fff;
    font-size: 0.8rem;
}
.copyright{
    text-align: center;
    margin-top: 1.5rem;
}
.f-box{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.f-box li{
    list-style: none;
}
.f-item{
    padding: 10%;
}
.f-item li+li{
    margin-top: 0.2rem;
}
.head{
    margin-bottom: 0.5rem;
}
a{
    text-decoration: none;
    color: #fff;
    font-size: 0.8rem;
}
a:hover:not(.btnFooter){
    color: #f56468;
}
.btnFooter{
    margin-top: 1rem;
}
@media (max-width: 760px){
    .f-box{
        grid-template-columns: 1fr;
        text-align: center;
    }
    h2{
        justify-content: center;
    }
}
</style>