<template>  
    <header>
      <div class="navbar" >
        <div id="location">
          <ul>
            <li><img class="metro-ico" src="~@/assets/img/mosmetro.png" alt="moscow metro logo"><span class="nodisplay">метро</span> <a href="#mapru" style="font-size: 0.7rem;">Дмитровская</a></li>
            <li> Дизайн Завод (ex. Флакон)</li>
            <li class="phone"><a href="tel:+74952012536"> 8(495)201-2536</a></li>
          </ul>
        </div>
          <ul class="links">
              <li><a href="quest-red-button">Красная кнопка</a></li>
              <li><a href="quest-lock-stock-and-two-playing-tables">Карты деньги два стола</a></li>
              <li><a href="quest-pepelac">Последний пепелац</a></li>
              <li><a href="quest-corporate-party">Корпоратив</a></li>
              <li><a href="quest-birthday-party">День рождения</a></li>
              <li><a href="quest-gift-certificate">Подарить</a></li>
          </ul>
          <div v-if="nobook">
            <a v-if="order" :href="`#order`" @click="navigateToPage('#order')" class="header-btn navbar-btn-big">Заказать</a>
            <a v-else :href="`#book`" @click="navigateToPage('#book')" class="header-btn navbar-btn-big">Забронировать</a>
          </div>
          <a v-else :href="`#book`" @click="navigateToPage('#book')" class="header-btn navbar-btn-big">Забронировать</a>
          <div class="toggle_btn">
                <img class="langc-icon" src="~@/assets/img/menu.png" alt="" v-if="!show" @click="toggleMenu" :key="toggleKey">
                <img class="langc-icon" src="~@/assets/img/close.png" alt="" v-else @click="closeMenu" :key="toggleKey">
          </div>
          <ul class="dropdown_menu" :class="{ 'open': show }">
            <li class="links-menu"><a href="quest-red-button">Красная кнопка</a></li>
            <li class="links-menu"><a href="quest-lock-stock-and-two-playing-tables">Карты деньги два стола</a></li>
            <li class="links-menu"><a href="quest-pepelac">Последний пепелац</a></li>
            <li class="links-menu"><a href="quest-corporate-party">Корпоратив</a></li>
            <li class="links-menu"><a href="quest-birthday-party">День рождения</a></li>
            <li class="links-menu"><a href="quest-gift-certificate">Подарить</a></li>
            <li v-if="nobook">
                <a v-if="order" :href="`#order`" @click="navigateToPage('#order')" class="header-btn">Заказать</a>
                <a v-else :href="`#book`" @click="navigateToPage('#book')" class="header-btn">Забронировать</a>
            </li>
            <li v-else>
                <a :href="`#book`" @click="navigateToPage('#book')" class="header-btn">Забронировать</a>
            </li>
        </ul>  
      </div>      
    </header>
</template>
<script>
export default {
    data() {
        return{
            show: false,
            toggleKey: 'menu',
        }
    },
    methods: {
        toggleMenu() {
            this.show = true;    
            this.toggleKey = 'clear'
        },
        closeMenu() {
            this.show = false;
            this.toggleKey = 'menu'
        },
        navigateToPage(sectionId) {
            this.$router.push(sectionId);
        },
    },
    props: {
        order: {
            type: Boolean,
            default: false
        },
        nobook: {
            type: Boolean,
            default: true
        }
    },
}
</script>
<style scoped>
/* NAVBAR */
.fa-solid, .fas, .white-text, .item-t, h1, li, a{
    list-style: none;
    color: #fff;
}
a{
    text-decoration: none;
    color: #fff;
    font-size: 0.7rem;
    text-transform: uppercase;
}
.navbar{
    width: 100%;
    height: 60px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.phone{
    transition: all .4s ease-in-out;
}
.phone:hover{
    scale: 1.04;
}
#location{
    color: #fff;
    font-size: 0.7em;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
}
#location a{
    font-size: 0.8rem;
    font-weight: 500;
}
.navbar .links {
    display: flex;
    gap: 2rem;
}

.toggle_btn{
    color: #fff;
    font-size: 1.3em;
    cursor: pointer!;
    vertical-align:middle;
    display: none;
}
.lang-ico, .langc-icon{
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    transition: all .4s ease-in-out;
}
.metro-ico{
    width: 0.8rem;
}
.lang-ico:hover, .langc-icon:hover{
    scale: 1.1;
}
.header-btn{
    font-size: 0.7em!important;
}
.links a:hover{
    color:#f56468;
}
/* DROPDOWN MENU  */
.dropdown_menu{
    display: none;
    position: absolute;
    right: 2rem;
    top: 60px;
    height: 0;
    width: 320px;
    background-color: rgba(26, 25, 25, 0.85);
    border-radius: 20px;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    transition: height .2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 3;
}
.dropdown_menu.open{
    height: auto;
    padding: 5%;
}
.dropdown_menu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown_menu .action-btn{
    width: 100%;
    display: flex;
    justify-content: center;
}
.dropdown_menu .links-menu a:hover{
    color:#f56468;
}
@media (max-width: 1200px){
    .navbar .links, .navbar .navbar-btn-big{
        display: none;
    }
    .navbar .toggle_btn {
        display: block;
    }
    .dropdown_menu {
        display: block;
    }
}
</style>