<template>
    <section id="logobar">
            <ul class="logo">
                <li class="offsite">официальный сайт</li>
                <li id="logotype" ><a href="/" class="logotype gradient-text" aria-label="Main page of our website">Кубикулум</a></li>
                <li class="onsite">Квесты</li>
                <li>
                    <ul class="logofooter">
                        <li>
                            <span>
                                <a href="/quest-english" aria-label="English verdion of our website"><img class="langc-icon" src="@/assets/img/icons-en.png" alt="English language icon"></a>
                                <!-- <img class="langc-icon" src="~@/assets/img/language.png" alt="" v-if="!open" @click="open = !open" key="language">
                                <img class="langc-icon" src="~@/assets/img/close.png" alt="" v-else @click="open = !open" key="langclear"> -->
                            </span>
                            <span class="lang-menu" v-bind:class="{ 'open': open}">
                                <a href="/"><img class="lang-ico" src="~@/assets/img/icons-ru.png" alt="Russian language icon"></a>
                                <a href="/quest-english"><img class="lang-ico" src="~@/assets/img/icons-en.png" alt="English language icon"></a>
                                <a href=""><img class="lang-ico no-display" src="~@/assets/img/icons-cn.png" alt="Chinese language icon"></a>
                            </span>
                        </li>
                        <li>
                            <img id="qslogo" src="~@/assets/img/QStransperent.png" alt="QuestStars logo">
                        </li>
                    </ul>
                </li>
            </ul>
    </section>
</template>
<script>
export default {
    data() {
        return {
            open: false
        };
    },
    methods: {
        
        navigateToPage(sectionId) {
            this.$router.push(sectionId);
        },
  },
}
</script>
<style scoped>
.fa-solid, .fas, .white-text, .item-t, h1, li{
    list-style: none;
    color: #fff;
}
#logobar{
    width: 100%;
    height: 100px+2rem;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
#logobar .logo{
    color: #fff;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
}
#logobar .logofooter{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
}
#logobar .logofooter a{
    cursor: pointer;
}
#logobar a{
    font-size: 1.7em;
    text-transform: uppercase;
    font-weight: 500;
}
#qslogo {
    height: 2rem;
    width: 2rem;
    color: #fff;
}
.gradient-text {
    background-image: linear-gradient(45deg, #f3ec78, #f56468);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
#logobar .offsite{
    font-size: 0.5em;
}
#logobar .onsite{
    font-size: 0.8em;
}
#logotype:active{
    scale: 0.98;
}
#logotype{
    transition: all .4s ease-in-out;
}
#logotype:hover{
    scale: 1.02;
}

.lang-menu{
    display: none;
}
.lang-menu.open{
    display: flex;
    position: absolute;
    height: auto;
    gap: 0.4rem;
}
.lang-ico, .langc-icon{
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    transition: all .4s ease-in-out;
}
@media (max-width: 1200px){
    #logobar{
        margin: -50px 0 2rem;
    }
}
@media (max-width: 620px){
    #logobar{
        margin-top: 0;
        margin-bottom: 0;
    }
}
</style>