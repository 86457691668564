<template>
    <section id="special">
        <div class="sec-head">
            <h2 v-if="oneQuest"><p> <span class="purple-text"> В квестах Кубикулум можно </span> <span class="red-text">провести праздник</span> <span class="purple-text"> по особым поводам</span></p></h2>
            <h2 v-else><p><span class="red-text">Особые мероприятия</span><span class="purple-text"> в квестах</span></p></h2>
        </div>
        <div class="s-box">
            <a class="s-item" href='/quest-corporate-party'>
                <span><i class="material-icons">corporate_fare</i></span>
                <h5>
                    Корпоративы<br> в квестах
                </h5>
            </a>
            <a class="s-item" href='/quest-birthday-party'>
                <span><i class="material-icons">celebration</i></span>
                <h5>
                    День рождения<br> в квесте
                </h5>
            </a>
            <a class="s-item" href='/quest-birthday-party/#animator'>
                <span><i class="material-icons">cake</i></span>
                <h5>
                    Детский<br>день рождения
                </h5>
            </a>
            <a class="s-item" href='/quest-gift-certificate'>
                <span><i class="material-icons">card_membership</i></span>
                <h5>
                    Подарочный<br> сертификат
                </h5>
            </a>
            <a class="s-item" href='/quest-english'>
                <span><i class="material-icons">language</i></span>
                <h5>
                    Квест<br> на английском
                </h5>
            </a>
            <a class="s-item item-book" href='#book'>
                <span ><i class="material-icons">border_color</i></span>
                <h5>
                    Забронировать
                </h5>
            </a>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        oneQuest: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        navigateToPage(sectionId) {
            this.$router.push(sectionId);
        },
    }
}
</script>
<style scoped>
a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}
#special{
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 4%;
}
h2{
    margin: 2rem 0;
}
.s-box{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center;
    align-items: center;
    padding: 2%;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border-radius: 10px;
}
.s-item{
    color: #525f7f;
    padding: 2%;
    transition: all .4s ease-in-out;
}
.s-item + .s-item{
    margin-right: 10px;
}
.item-book{
    border-left: dotted;
}
.s-item:hover{
    scale: 1.04;
    cursor: pointer;
}
@media (max-width: 1200px){
    .s-box{
        margin: 10px 1%;
    }
    .item-book{
    border-left: none;
    }
}
@media (max-width: 790px){
    .s-box{
        grid-template-columns: repeat(3, 1fr);
    }
    .s-item + .s-item{
    margin: 1rem;
    }
}
@media (max-width: 400px){
    .s-box{
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>