<template>
  <HeaderRu />
  <QuestsRu />
  <!-- <ExplainRu /> -->
  <WannaPlay v-bind:wanna=wanna />
  <CallFARu />
  <SpecialRu />
  <WeknowRu />
  <TestiRu />
  <BookRu />
  <MapRu />
  <ContactFormRu />
  <FooterRu />
</template>

<script>
  import HeaderRu from '../components/HeaderRu.vue';
  import QuestsRu from '../components/QuestsRu.vue';
  import WannaPlay from '../components/WannaPlayRu.vue';
  import CallFARu from '../components/CallFARu.vue';
  import SpecialRu from '../components/SpecialRu.vue';
  import WeknowRu from '../components/WeknowRu.vue';
  import TestiRu from '../components/TestiRu.vue';
  import BookRu from '../components/BookRu.vue';
  import MapRu from '../components/MapRu.vue';
  import ContactFormRu from '../components/ContactFormRu.vue';
  import FooterRu from '../components/FooterRu.vue';
  
  export default {
    components: {
      HeaderRu,
      QuestsRu,
      WannaPlay,
      CallFARu,
      SpecialRu,
      WeknowRu,
      TestiRu,
      BookRu,
      MapRu,
      ContactFormRu,
      FooterRu
    },
    data() {
      return{
        language: 'ru',
        quesrName: [
                    "No",
                    false
          ],
        wanna: [
        true,
        "Хотите поиграть?",
        "КВЕСТЫ – УНИВЕРСАЛЬНОЕ РАЗВЛЕЧЕНИЕ, КОТОРОЕ ПОДОЙДЕТ ВСЕМ!",
        "ВЗРОСЛЫМ КОМАНДАМ",
        "ЛЮДИ ВСЕХ ВОЗРАСТОВ ИГРАЮТ В КВЕСТЫ",
        "Студенты приходят после учёбы. Коллеги после работы. Бабушки 70 лет праздную дни рождения. Пары приходят на свидания. Друзья, которые не часто собираются, находят у нас классную командую игру.",
        "ДЛЯ ИГРЫ В КВЕСТ НЕ НУЖЕН ОСОБЫЙ ПОВОД!",
        "Можно найти 365 поводов в году.",
        "СЕМЬЯМ С ДЕТЬМИ",
        "ПОНРАВИТСЯ ВСЕМ УЧАСТНИКАМ,",
        "а не либо взрослым / либо детям.", //10
        "ДЕТЯМ ПОНРАВИТСЯ",
        "музыка, декорации квеста, загадки, и, конечно, играть вместе с родителями.",
        "ВЗРОСЛЫМ ПОНРАВИТСЯ",
        "атмосфера квестов, интересные логические ходы и командная игра с детьми, которые иногда дают фору взрослым!",
        "ДЕТСКИМ КОМАНДАМ", //15
        "ДЕТИ ОТ 7 ДО 12 ЛЕТ",
        "смогут сыграть в команде со старшими игроками или для них можно заказать адаптированную под возраст программу в сопровождении аниматора.",
        "ДЕТИ СТАРШЕ 12 ЛЕТ",
        "хотят играть самостоятельно и не в детские квесты, а в сложные и остросюжетные! Они смогут справиться с заданиями наших квестов без сопровождающих.",
        ],
      }
    }
  }
  </script>
  <style>
  #app {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    /* other styles */
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
    color: #45668e;
    /* color: 020202; */
  }
  h2, h3 {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 0 1% 0;
  }
  h2 {
      font-weight: bolt;
      font-size: 1.5em;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
  }
  h3 {
      font-weight: 300;
      letter-spacing: 0.4rem;
      font-size: 1.1rem;
  }
  .sec-head{
    margin: 2rem 1rem;
  }
  .red-text {
      color: #f56468;
  }
  .boltText {
    font-weight: 600;
  }
  .white-text {
      color: #fff;
  }
  .upText {
    text-transform: uppercase;
  }
  .purple-text{
      /* color: #020202; */
      color: #45668e;
  }
  .header-btn{
      display: inline-block;
      padding: 6px 10px;
      color: #cafaea;
      border: 1px solid #cafaea;
      background-color: none;
      border-radius: 3px;
      font-size: 1rem;
      font-weight: 700;
      line-height: normal;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      transition: all .4s ease-in-out;
      cursor: pointer!important;
  }
  .header-btn:hover{
      scale: 1.04;
  }
  .header-btn:active{
    scale: 1;
  }
  .nodisplay, #slideDate{
      display: none!important;
  }
  .textCenter{
    text-align: center;
  }
  @media (max-width: 790px) {
    h3 {
      font-size: 1rem;
    }
  }
  </style>
  
