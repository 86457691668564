// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

// Настройка axios для отправки cookies с запросами
axios.defaults.withCredentials = true;

createApp(App)
  .use(router)
  .mount('#app');
