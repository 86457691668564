<template>
  <router-view/>
</template>
<script>
export default {
  watch: {
    $route(to) {
      if (to.meta) {
        if (to.meta.title) {
          document.title = to.meta.title;
        }
        if (to.meta.description) {
          const metaDescription = document.querySelector('meta[name="description"]');
          if (metaDescription) {
            metaDescription.setAttribute('content', to.meta.description);
          } else {
            const newMeta = document.createElement('meta');
            newMeta.setAttribute('name', 'description');
            newMeta.setAttribute('content', to.meta.description);
            document.head.appendChild(newMeta);
          }
        }
        if (to.meta.keywords) {
          const metaKeywords = document.querySelector('meta[name="keywords"]');
          if (metaKeywords) {
            metaKeywords.setAttribute('content', to.meta.keywords);
          } else {
            const newMeta = document.createElement('meta');
            newMeta.setAttribute('name', 'keywords');
            newMeta.setAttribute('content', to.meta.keywords);
            document.head.appendChild(newMeta);
          }
        }
      }
    }
  }
}
</script>

<style>
#app {
  
  
}
.no-display{
  display: none!important;
}
.grecaptcha-badge { 
    visibility: hidden !important;
}
</style>
