<template>
    <section id="weknow">
        <div class="sec-head">
            <h2>
                <p>МЫ ТОЧНО ЗНАЕМ, КАК СДЕЛАТЬ <span class="red-text">ИГРУ ИНТЕРЕСНОЙ!</span></p>
            </h2>
        </div>
        <div class="we-box">
            <div class="we-item">
                <div>
                    <i class="material-icons we-icon">view_in_ar</i>
                </div>
                <div class="we-head">
                    КРАСИВАЯ РЕАЛЬНОСТЬ
                </div>
                <div class="we-text">
                    Декорации помещений, игровые предметы, каждая деталь тщательно проработаны, чтобы вы почувствовали себя именно в том времени и месте, где происходят события квеста. А некоторые технические решения квестов приводят в восторг наших игроков!
                </div>
            </div>
            <div class="we-item">
                <div>
                    <i class="material-icons we-icon">timeline</i>
                </div>
                <div class="we-head">
                    СЮЖЕТ И АТМОСФЕРА
                </div>
                <div class="we-text">
                    Цель квеста задает основной сюжет и атмосферу. Музыка, декорации, освещение и последовательность заданий выстроены так, что вы полностью погружаетесь в историю и верите в реальность происходящего!
                </div>
            </div>
            <div class="we-item">
                <div>
                    <i class="material-icons we-icon">interests</i>
                </div>
                <div class="we-head">
                    ИНТЕРЕСНЫЕ ЗАДАНИЯ
                </div>
                <div class="we-text">
                    Большинство заданий наших квестов не сложные, но при этом и не очевидные. Решение “лежит на поверхности” и требует от вас только внимательности и немного логики. Каждое такое задание оставляет приятное “послевкусие” от инсайта: так вот же как легко это решается!
                </div>
            </div>
            <div class="we-item">
                <div>
                    <i class="material-icons we-icon">bolt</i>
                </div>
                <div class="we-head">
                    ДИНАМИЧНОСТЬ ИГРЫ
                </div>
                <div class="we-text">
                    Мы долго тестировали и дорабатывали задания квестов, чтобы вы нигде не заскучали и не потеряли азарта игры! А наши ведущие следят за игрой по камерам и всегда знают, когда предложить подсказку, если она требуется.
                </div>
            </div>
            
        </div>
    </section>
</template>
<script>
    export default{
        name: 'WeKnowRu'
    }
</script>
<style>
#weknow{
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}
.we-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    padding: 0 5%;
}
.we-item{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    gap: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 30px;
    padding: 10%;
    transition: all .4s ease-in-out;
}
.we-icon{
    font-size: 48px!important;
    color: #f56468;
}
.we-item:hover{
    scale: 1.02;
}
.we-head{
    font-size: 1rem;
    font-weight: 500;
    color: #f56468;
}
.we-text{
    font-size: 0.8rem;
}
@media (max-width: 1070px){
    .we-box{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 790px){
    .we-box{
        grid-template-columns: 1fr;
    }
}
</style>