<template>
    <div id="header">
        <MenuRu />
        <main>
            <LogoBar />
            <section id="hero">
                    <div>
                        <h1>КВЕСТ - ЭТО УВЛЕКАТЕЛЬНОЕ ПРИКЛЮЧЕНИЕ ДЛЯ НЕБОЛЬШОЙ КОМПАНИИ</h1>
                    </div>
                    <div class="features-container">
                        <ul class="features fa-ul">
                            <li>
                                Ваша команда 2-5 человек
                            </li>
                            <li>
                                1 час игрового времени
                            </li>
                            <li>
                                3 сюжета на выбор
                            </li>
                            <li>
                                Внимательность, логика и хорошее настроение
                            </li>
                            <li>
                                Без "страшилок" и физической нагрузки
                            </li>
                        </ul>
                    </div>
                    <div class="b-box">
                        <p class="hero-action"><a :href="`#quests`" @click="navigateToPage('#quests')" class="header-btn">Выбрать квест</a></p>
                        <p class="hero-action white-text">или</p>
                        <p class="hero-action"><a :href="`#book`" @click="navigateToPage('#book')" class="header-btn">Забронировать</a></p>
                    </div>
                    <div class="hero-action-big">
                        <a :href="`#quests`" @click="navigateToPage('#quests')" class="header-btn">Выбрать квест</a>
                    </div>
            </section>
        </main>
    </div>
</template>

<script>
import MenuRu from '../components/headers/MenuRu.vue';
import LogoBar from '../components/headers/LogobarRu.vue';
export default {
  components: {
    MenuRu,
    LogoBar,
  },
  methods: {
    navigateToPage(sectionId) {
        this.$router.push(sectionId);
    },
  }
};
</script>

<style scoped>
  #header{
    height: 100vh;
    background-color: #000;
    background-image:linear-gradient(to top, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.8) 100%), 
    url(../assets/img/header-scaled.webp);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: calc((100vw/10)*1%);
    padding-top: 5px;
  }
.fa-solid, .fas, .white-text, .item-t, h1{
    color: #fff;
}
#header li{
    list-style: none;
    color: #fff;
}
.header-btn:active{
    scale: 0.98;
}
/* HERO */
section#hero {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    color: #fff;
    padding: 1%;
}
#hero h1{
    font-size: 1.5rem;
    font-weight: 500;
}
.features-container{
    margin: 0 auto;
    padding: 0 5%;
}
.features{
    text-align: left;
    font-size: 1.1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
}
.features li:not(:last-child) {
    margin-bottom: 5px;
}
.b-box {
    display: none;   
}
.fa-ul{
    z-index: 2!important;
}
@media (max-width: 1200px){
    .hero-action-big{
        display: none;
    }
    .b-box{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.7rem;
    }
}
@media (max-width: 920px){
    #header{
        height: 105vh;
        background-attachment:scroll;
        background-image:linear-gradient(to top, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.8) 100%), 
        url(../assets/img/header-scaled-mob.webp);
    }
    .features{
        margin: 0.8rem;
    }
}
@media (max-width: 500px){
    #hero h1{
        margin: 1rem;
        font-size: 1.2rem;
    }
    .features li {
        font-size: 1rem;
    }
    .hero-action{
        margin-inline: 1%;
    }
    .header-btn {
        font-size: 0.8rem;
    }
}
@media (max-width: 383px){
    .b-box{
        flex-wrap: wrap;
    }
}
</style>
