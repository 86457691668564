<template>
    <section id="mapru">
        <div class="sec-head">
            <h2><p>Находимся на <span class="red-text">Дизайн Заводе</span> (ex. Флакон)</p></h2>
            <h3><p>В 7 МИНУТАХ ОТ <span class="red-text">МЕТРО ДМИТРОВСКАЯ</span></p></h3>
        </div>
        <div class="map">
            <img loading="lazy" id="map" :src="resolvedMapName" alt="">
        </div>
    </section>
</template>
<script>

export default {
  name: 'MapRu',
  props: {
    mapName: {
        type: String,
        default: "mapFlaconBig"
    }
  },
  computed: {
    resolvedMapName() {
        const imageName = this.mapName === "mapFlaconBig" ? "mapFlaconBig.webp" : "mapFlaconRests.webp";
        return require(`@/assets/img/maps/${imageName}`);
    }
  }
}
</script>
<style scoped>
#map{
    display: block;
    margin: 0 auto;
    max-width: 80vw;
}
.sec-head{
    margin-bottom: 0;
}
</style>