<template>
    <div>
        <section id="quests" title="Выбор квеста">
            <div v-if="questName[0] === 'No'">
                <div v-if="questName[1]">
                    <h2 class="upText"><p><span class="purple-text">EVERY</span> <span class="red-text"> escape room</span></p></h2>
                    <h3>IS A CAPTIVATING ADVENTURE WHICH CARRIES YOU OVER THE OTHER REALITY</h3>
                </div>
                <div v-else>
                    <h2><p><span class="purple-text">КАЖДЫЙ</span> <span class="red-text"> КВЕСТ</span></p></h2>
                    <h3>ЗАХВАТЫВАЮЩЕЕ ПРИКЛЮЧЕНИЕ, КОТОРОЕ ПЕРЕНОСИТ ВАС В ДРУГУЮ РЕАЛЬНОСТЬ</h3>
                </div>
            </div>
            <div v-else>
                <div v-if="questName[1]">
                    <h2 class="upText"><p><span class="purple-text">We have</span> <span class="red-text"> 2 more escape rooms on our location</span></p></h2>
                    <h3>IF YOUR TEAM HAVE FROM 6 TO 15 PLAYERS, YOU CAN PLAY IN A SAME TIME BY 2 OR 3 TEAM IN DIFFERENT ROOMS.</h3>
                </div>
                <div v-else>
                    <h2><p><span class="purple-text">На нашей площадке есть еще</span> <span class="red-text"> 2 квеста</span></p></h2>
                    <h3>ЕСЛИ У ВАС ОТ 6 ДО 15 ИГРОКОВ, ВЫ МОЖЕТЕ РАЗДЕЛИТЬСЯ НА 2-3 КОМАНДЫ И СЫГРАТЬ В РАЗНЫЕ КВЕСТЫ ОДНОВРЕМЕННО.</h3>
                </div>
            </div>
            <div v-if="questName[1]" class="box">
                <a v-if="questName[0] != 'RedButton'" id="rb" href="/escape-room-red-button" class="item">
                    <a href="/escape-room-red-button" class="item-h">Red button</a>
                    <p class="item-t">You are a taskforce. Your goal is to infiltrate a secret Soviet-era bunker to prevent the nuclear missile launch. On the road to the red button you are needed to work with real military encryption.</p>
                    <a href="/escape-room-red-button" class="item-btn">more</a>
                </a>
                <a v-if="questName[0] != 'KDDS'" id="kdds" href="/escape-room-lock-stock-and-two-playing-tables" class="item">
                    <a href="/escape-room-lock-stock-and-two-playing-tables" class="item-h">Lock stock and two playing tables</a>
                    <p class="item-t">You get to the underground casino. Every item in the room conceals a mystery with which you will have to figure out if you want to beat the casino and not to get into trouble.</p>
                    <a href="/escape-room-lock-stock-and-two-playing-tables" class="item-btn">more</a>
                </a>
                <a v-if="questName[0] != 'Pepelac'" id="ppc" href="/escape-room-pepelac" class="item">
                    <a href="/escape-room-pepelac" class="item-h">Pepelac (Last Spaceship)</a>
                    <p class="item-t">You get to the abandoned planet. The only way to come home is to steal the old spaceship which is similar to puffing tin can in the steampunk style.</p>
                    <a href="/escape-room-pepelac" class="item-btn">more</a>
                </a>
            </div>
            <div v-else class="box">
                <a v-if="questName[0] != 'RedButton'" id="rb" href="/quest-red-button" aria-label="Red button Last spaceship escape room quest" class="item">
                    <p href="/quest-red-button" class="item-h">Красная кнопка</p>
                    <p class="item-t">Вы спецотряд. Ваша цель - проникнуть в секретный бункер времен СССР, чтобы предотвратить запуск ядерных ракет. На пути к красной кнопке вас ждут настоящие военные шифровки.</p>
                    <p class="item-btn">Подробнее</p>
                </a>
                <a v-if="questName[0] != 'KDDS'" id="kdds" href="/quest-lock-stock-and-two-playing-tables" aria-label="Cards money and two tables escape room quest" class="item">
                    <p href="/quest-lock-stock-and-two-playing-tables" class="item-h">Карты деньги два стола</p>
                    <p class="item-t">Вы попали в подпольное казино. Каждый предмет в этом помещении таит в себе загадку, с которой вам придется разобраться, если вы хотите обыграть казино и не попасть в неприятности.</p>
                    <p class="item-btn">Подробнее</p>
                </a>
                <a v-if="questName[0] != 'Pepelac'" id="ppc" href="/quest-pepelac" aria-label="Pepelac Last spaceship escape room quest" class="item">
                    <p href="/quest-pepelac" class="item-h" >Последний пепелац</p>
                    <p class="item-t">Вы попадаете на заброшенную планету. Единственный шанс вернуться домой – угнать старый космический корабль, который больше похож на пыхтящую консервную банку в стиле стимпанк.</p>
                    <p class="item-btn">Подробнее</p>
                </a>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  name: 'HeaderRu',
  methods: {
    navigateToPage(sectionId) {
        this.$router.push(sectionId);
    },
  },
  props: {
        questName: {
            type: Array,
            default() {
                return [
                    "No",
                    false
                ]
            } 
        },
    },
}
</script>
<style scoped>
/* QUESTS */

#quests {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 1rem;
    padding: 20px 5%;
}
.item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 10% auto 10%;
    color: #fff;
    border-radius: 50px;
    padding: 10%;
    min-height: 280px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    cursor: pointer;
}
.item::before {
    transition-property: fill, color, background-color, stroke-width, border-style, border-width, border-top-width, border-left-width, border-right-width, border-bottom-width, custom-border, borders, box-shadow, text-shadow, opacity, border-radius, stroke, border-color, font-size, font-style, font-weight, text-decoration, letter-spacing, transform, background-image, image-zoom, background-size, background-position;
}
.item-h {
    font-size: 1rem;
    color: #f56468;
    text-transform: uppercase;
    font-weight: 600;
}
.item-t {
    font-size: 0.8rem;
    padding: 2rem 1rem;
    font-weight: 400;
    color: #fff;
}
.item-btn {
    color: #fff;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 0.8rem;
}

#rb {
    background-image:linear-gradient(to top, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.8) 100%),  
    url(~@/assets/img/Red_button.webp);
}
#kdds {
    background-image:linear-gradient(to top, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.8) 100%),  
    url(~@/assets/img/Lock_stock_and_two_playing_tables.webp);
}
#ppc {
    background-image:linear-gradient(to top, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.8) 100%),  
    url(~@/assets/img/Last_Spaceship.webp);
}
.item{
    background-position: center;
    background-repeat: no-repeat;
    background-size: 130%;
    transition: all .7s ease-in-out;
}
.item:hover{
    transform: scale(1.03, 1.03);
    background-size: 140%;
}
a{
    text-decoration: none;
}
h3{
    margin: auto 5%;
}
@media (max-width: 1060px) {
    .box {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        gap: 1rem;
        padding: 5%;
    }
}
</style>