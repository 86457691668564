<template>
    <section id="wannaplay" class="u-section">
      <div v-if="wanna[0]" class="sec-head">
        <h2 v-if="wanna[1] === 1">
          <p><span class="purple-text">Birthday in</span> <span class="red-text">Cubiculum</span> <span class="purple-text">Escape rooms</span> </p>
        </h2>
        <h2 v-else><p class="red-text">{{wanna[1]}}</p></h2>
        <h3 class="upText">{{wanna[2]}}</h3>
      </div>
      <div class="wp-box">
        <div class="wp-item-b">
          <div class="wp-item">
            <img 
              loading="lazy"
              :src="imageSrc1" 
              alt="" 
              class="wp-img">
          </div>
          <div class="wp-line">
            <hr width="100%" size="12" />
          </div>
          <div class="wp-text">
            <h4>{{ wanna[3] }}</h4>
            <p>
              <span class="red-text upText">{{ wanna[4] }}</span> <span class="purple-text">{{ wanna[5] }}</span>
            </p>
            <p v-if="wanna[0]">
              <span class="red-text"> {{ wanna[6] }} </span> <span class="purple-text">{{ wanna[7] }}</span>
            </p>
          </div>
        </div>
        <div class="wp-item-b">
          <div class="wp-item">
            <img 
              loading="lazy"
              :src="imageSrc2" 
              alt="" 
              class="wp-img">
          </div>
          <div class="wp-line">
            <hr width="100%" size="12" />
          </div>
          <div class="wp-text">
            <h4>{{wanna[8]}}</h4>
            <p>
              <span class="red-text upText">{{wanna[9]}}</span> <span class="purple-text">{{ wanna[10] }}</span>
            </p>
            <p v-if="wanna[0]">
              <span class="red-text upText">{{ wanna[11] }}</span> <span class="purple-text">{{ wanna[12] }}</span>
            </p>
            <p v-if="wanna[0]">
              <span class="red-text upText">{{ wanna[13] }}</span> <span class="purple-text">{{wanna[14]}}</span>
            </p>
          </div>
        </div>
        <div class="wp-item-b">
          <div class="wp-item">
            <img 
              loading="lazy"
              :src="imageSrc3" 
              alt="" 
              class="wp-img">
          </div>
          <div class="wp-line">
            <hr width="100%" size="12" />
          </div>
          <div class="wp-text"> 
            <h4>{{ wanna[15] }}</h4>
            <p>
              <span class="red-text upText">{{ wanna[16] }}</span> <span class="purple-text">{{ wanna[17] }} </span>
            </p>
            <p v-if="wanna[0]">
              <span class="red-text upText">{{ wanna[18] }}</span> <span class="purple-text">{{ wanna[19] }}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    props: {
      wanna: {
        type: Array,
        default() {
          return [true];
        },
      },
    },
    name: 'WannaPlay',
    computed: {
      imageSrc1() {
        return this.isMobile ? require('@/assets/img/wannaplay1-600.webp') : require('@/assets/img/wannaplay1.webp');
      },
      imageSrc2() {
        return this.isMobile ? require('@/assets/img/wannaplay2-600.webp') : require('@/assets/img/wannaplay2.webp');
      },
      imageSrc3() {
        return this.isMobile ? require('@/assets/img/wannaplay3-600.webp') : require('@/assets/img/wannaplay3.webp');
      },
      isMobile() {
        return window.innerWidth <= 790;
      },
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.$forceUpdate();
      },
    },
  };
  </script>
  
  <style scoped>
  /* WANNA PLAY */
  #wannaplay {
    display: grid;
    grid-template-rows: repeat(3, auto);
  }
  .wp-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    padding: 0 10%;
  }
  .wp-item {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    align-items: end;
    padding: 5% 5% 0;
  }
  .wp-line {
    background-color: #f56468;
    border-color: #f56468;
  }
  .wp-text h4 {
    font-size: 2vm;
    align-items: top;
  }
  .wp-text .red-text {
    font-weight: 600;
  }
  .wp-text {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    gap: 0.4rem;
    padding: 5%;
    font-size: 0.85rem;
    line-height: 1.4rem;
  }
  .wp-img {
    max-width: 100%;
  }
  a {
    color: #f56468;
    text-decoration: none;
  }
  @media (max-width: 790px) {
    .wp-img {
      width: 350px;
      height: 350px;
      margin: 0 auto;
    }
    .wp-box {
      display: grid;
      grid-template-columns: 1fr !important;
      padding: 0 3%;
    }
    .wp-line {
      background-image: linear-gradient(45deg, #f56468, #cafaea);
      border: none;
    }
  }
  </style>
  